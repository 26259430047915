import { errorHandler, swaggerClient } from '../../swagger';

class Subscription {
	static fetchSubscription = async (userId, subscriptionId) => {
		try {
			const client = await swaggerClient();
			if (!client || !client.apis || !client.apis.subscription) {
				console.error('Swagger client or subscription API not available');
				throw new Error('Subscription API not available');
			}

			const result = await client.apis.subscription.getSubscriptionById({
				userId: userId,
				subscriptionId: subscriptionId
			});

			return JSON.parse(result.data);
		} catch (err) {
			console.error('Error fetching subscription:', err);
			errorHandler(err);
			throw err;
		}
	};

	static async fetchPlans(currency = 'INR') {
		try {
			// First attempt - using swagger client
			try {
				const client = await swaggerClient();

				// Try subscription.getPlans first
				if (client?.apis?.subscription?.getPlans) {
					const response = await client.apis.subscription.getPlans({
						currency
					});
					if (response.status === 200 && response.data) {
						return JSON.parse(response.data);
					}
				}

				// Try alternate plan.getPlans path
				if (client?.apis?.plan?.getPlans) {
					const response = await client.apis.plan.getPlans({ currency });
					if (response.status === 200 && response.data) {
						return JSON.parse(response.data);
					}
				}

				console.warn('No valid API endpoint found for plans');
			} catch (swaggerError) {
				console.error('Swagger client error:', swaggerError);
			}

			// Fallback to direct fetch
			const apiUrl = `${window.location.origin}/v1/plans?currency=${currency}`;
			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error('Error fetching subscription plans:', error);
			return [];
		}
	}

	// web-kamero-full/src/pouchDB/models/Subscription.js

	// Update the createSubscription method to include GST
	// Update the createSubscription method in Subscription.js to include the redirectBaseURL
	static async createSubscription(userId, subscriptionData) {
		try {
			// Log the subscription data
			console.log('Creating subscription with data:', JSON.stringify(subscriptionData));

			// Ensure GST fields are set for INR
			if (
				(subscriptionData.currency === 'INR' ||
					subscriptionData.checkoutType === 'INR' ||
					subscriptionData.checkoutType === 'india') &&
				!subscriptionData.gstAmount
			) {
				const baseAmount = subscriptionData.amount;
				const gstAmount = baseAmount * 0.18; // 18% GST
				const totalAmount = baseAmount + gstAmount;

				// Add GST-related fields to the subscription data
				subscriptionData.gstAmount = gstAmount;
				subscriptionData.gstPercentage = 18;
				subscriptionData.totalAmount = totalAmount;

				console.log('Added GST calculation:', {
					baseAmount,
					gstAmount,
					totalAmount
				});
			}

			// Make sure we have a redirectBaseURL
			if (!subscriptionData.redirectBaseURL) {
				subscriptionData.redirectBaseURL = window.location.origin;
				console.log('Added redirect base URL:', subscriptionData.redirectBaseURL);
			}

			// Add checkoutType if it doesn't exist
			if (!subscriptionData.checkoutType) {
				subscriptionData.checkoutType = subscriptionData.currency === 'INR' ? 'INR' : 'USD';
			}

			// Handle upgrade or addon mode
			const purchaseMode = subscriptionData.purchaseMode || 'new';

			let apiEndpoint = '';

			if (purchaseMode === 'upgrade') {
				apiEndpoint = `/v1/subscription/${userId}/upgrade`;
			} else if (purchaseMode === 'addon') {
				apiEndpoint = `/v1/subscription/${userId}/addon`;
			} else {
				apiEndpoint = `/v1/subscription/${userId}/purchase`;
			}

			// First attempt - using swagger client
			try {
				const client = await swaggerClient();

				// Check if the appropriate API endpoint exists in Swagger
				if (client?.apis?.subscription?.createSubscription && purchaseMode === 'new') {
					const response = await client.apis.subscription.createSubscription({
						userId: userId,
						body: subscriptionData
					});

					if (response.status === 200 && response.data) {
						const result = JSON.parse(response.data);

						// Store the internal order ID in localStorage for later verification
						if (result.orderId && result.checkoutLink) {
							// Extract the subscription/external ID from the checkout link
							const parts = result.checkoutLink.split('/');
							const externalId = parts[parts.length - 1];

							if (externalId) {
								console.log('Storing order mapping:', {
									externalId,
									orderId: result.orderId
								});
								localStorage.setItem(`order_${externalId}`, result.orderId);
							}
						}

						return result;
					}
				} else if (client?.apis?.subscription?.upgradeSubscription && purchaseMode === 'upgrade') {
					// Similar implementation for upgrade
					const response = await client.apis.subscription.upgradeSubscription({
						userId: userId,
						body: subscriptionData
					});

					if (response.status === 200 && response.data) {
						const result = JSON.parse(response.data);
						// Handle saving order ID to localStorage (same as above)
						return result;
					}
				} else if (client?.apis?.subscription?.addonSubscription && purchaseMode === 'addon') {
					// Similar implementation for addon
					const response = await client.apis.subscription.addonSubscription({
						userId: userId,
						body: subscriptionData
					});

					if (response.status === 200 && response.data) {
						const result = JSON.parse(response.data);
						// Handle saving order ID to localStorage (same as above)
						return result;
					}
				}

				console.warn('No valid API endpoint found for subscription creation via Swagger');
			} catch (swaggerError) {
				console.error('Swagger client error:', swaggerError);
			}

			// Fallback to direct fetch with the appropriate endpoint
			const apiUrl = `${window.location.origin}${apiEndpoint}`;
			console.log(`Using direct fetch to ${apiUrl}`);

			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(subscriptionData),
				credentials: 'include' // Include cookies for auth
			});

			if (!response.ok) {
				const errorText = await response.text();
				console.error('API error response:', errorText);

				try {
					const errorData = JSON.parse(errorText);
					throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
				} catch (e) {
					throw new Error(
						`HTTP error! status: ${response.status}, response: ${errorText.substring(0, 100)}`
					);
				}
			}

			const result = await response.json();

			// Store the internal order ID in localStorage for later verification
			if (result.orderId && result.checkoutLink) {
				// Extract the subscription/external ID from the checkout link
				const parts = result.checkoutLink.split('/');
				const externalId = parts[parts.length - 1];

				if (externalId) {
					console.log('Storing order mapping:', {
						externalId,
						orderId: result.orderId
					});
					localStorage.setItem(`order_${externalId}`, result.orderId);
				}
			}

			return result;
		} catch (error) {
			console.error('Error creating subscription:', error);
			throw error;
		}
	}
	// // Update the verifyPayment method to include redirectBaseURL
	// static async verifyPayment(userId, paymentData) {
	// 	try {
	// 		// Get the current domain to use as redirectBaseURL
	// 		const currentDomain = window.location.origin;
	// 		console.log('Using current domain for payment verification:', currentDomain);

	// 		// Add redirectBaseURL to the payment data
	// 		paymentData.redirectBaseURL = currentDomain;

	// 		// Log complete data for debugging
	// 		console.log('Verifying payment with data:', JSON.stringify(paymentData));

	// 		// For INR payments, ensure we're verifying with GST
	// 		if (
	// 			paymentData.currency === 'INR' ||
	// 			(paymentData.gateway === 'razorpay' && !paymentData.currency)
	// 		) {
	// 			// This tells the backend to include GST in the verification
	// 			paymentData.includeGST = true;
	// 		}

	// 		// First attempt - using swagger client
	// 		try {
	// 			const client = await swaggerClient();

	// 			if (client?.apis?.subscription?.verifySubscriptionPayment) {
	// 				console.log('Using swagger client for verification');
	// 				const response = await client.apis.subscription.verifySubscriptionPayment({
	// 					userId: userId,
	// 					body: paymentData
	// 				});

	// 				if (response.status === 200 && response.data) {
	// 					return JSON.parse(response.data);
	// 				}
	// 			}

	// 			console.warn('No valid API endpoint found for payment verification via Swagger');
	// 		} catch (swaggerError) {
	// 			console.error('Swagger client error:', swaggerError);
	// 		}

	// 		// Fallback to direct fetch - use the correct API path
	// 		const apiUrl = `${window.location.origin}/v1/subscription/${userId}/verify-payment`;
	// 		console.log('Payment verification details:', {
	// 			userId,
	// 			gateway: paymentData.gateway,
	// 			subscriptionId: paymentData.subscriptionId,
	// 			paymentId: paymentData.paymentId,
	// 			orderId: paymentData.orderId,
	// 			includeGST: paymentData.includeGST,
	// 			redirectBaseURL: paymentData.redirectBaseURL
	// 		});
	// 		console.log('Falling back to direct fetch at URL:', apiUrl);

	// 		const response = await fetch(apiUrl, {
	// 			method: 'POST',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json'
	// 			},
	// 			body: JSON.stringify(paymentData),
	// 			credentials: 'include' // Include cookies for auth
	// 		});

	// 		console.log('Response status:', response.status);

	// 		if (!response.ok) {
	// 			const errorText = await response.text();
	// 			console.error('Error response:', errorText);

	// 			try {
	// 				const errorData = JSON.parse(errorText);
	// 				throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
	// 			} catch (e) {
	// 				throw new Error(
	// 					`HTTP error! status: ${response.status}, response: ${errorText.substring(0, 100)}`
	// 				);
	// 			}
	// 		}

	// 		return await response.json();
	// 	} catch (error) {
	// 		console.error('Error verifying payment:', error);
	// 		throw error;
	// 	}
	// }

	// // Update getRazorpayCheckoutDetails to include redirectBaseURL
	// static async getRazorpayCheckoutDetails(userId, razorpaySubscriptionId) {
	// 	try {
	// 		// Get the current domain
	// 		const currentDomain = window.location.origin;
	// 		console.log('Using current domain for Razorpay checkout:', currentDomain);

	// 		// First attempt - using swagger client
	// 		try {
	// 			const client = await swaggerClient();

	// 			if (client?.apis?.subscription?.getSubscriptionRazorpayCheckout) {
	// 				const response = await client.apis.subscription.getSubscriptionRazorpayCheckout({
	// 					userId: userId,
	// 					razorpay_subscription_id: razorpaySubscriptionId,
	// 					redirectBaseURL: currentDomain // Add redirectBaseURL parameter
	// 				});

	// 				if (response.status === 200 && response.data) {
	// 					return JSON.parse(response.data);
	// 				}
	// 			}

	// 			console.warn('No valid API endpoint found for Razorpay checkout via Swagger');
	// 		} catch (swaggerError) {
	// 			console.error('Swagger client error:', swaggerError);
	// 		}

	// 		// Fallback to direct fetch with query param for redirectBaseURL
	// 		const apiUrl = `${
	// 			window.location.origin
	// 		}/v1/subscription/${userId}/razorpay/${razorpaySubscriptionId}?redirectBaseURL=${encodeURIComponent(
	// 			currentDomain
	// 		)}`;
	// 		const response = await fetch(apiUrl, {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json'
	// 			},
	// 			credentials: 'include' // Include cookies for auth
	// 		});

	// 		if (!response.ok) {
	// 			const errorData = await response.json();
	// 			throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
	// 		}

	// 		return await response.json();
	// 	} catch (error) {
	// 		console.error('Error getting Razorpay checkout details:', error);
	// 		throw error;
	// 	}
	// }

	// // Update getPaypalCheckoutDetails to include redirectBaseURL
	// static async getPaypalCheckoutDetails(userId, paypalSubscriptionId) {
	// 	try {
	// 		// Get the current domain
	// 		const currentDomain = window.location.origin;
	// 		console.log('Getting PayPal checkout details', {
	// 			userId,
	// 			paypalSubscriptionId,
	// 			redirectBaseURL: currentDomain
	// 		});

	// 		// First attempt - using swagger client
	// 		try {
	// 			const client = await swaggerClient();

	// 			if (client?.apis?.subscription?.getSubscriptionPaypalCheckout) {
	// 				const response = await client.apis.subscription.getSubscriptionPaypalCheckout({
	// 					userId: userId,
	// 					paypal_subscription_id: paypalSubscriptionId,
	// 					redirectBaseURL: currentDomain // Add redirectBaseURL parameter
	// 				});

	// 				if (response.status === 200 && response.data) {
	// 					return JSON.parse(response.data);
	// 				}
	// 			}

	// 			console.warn('No valid API endpoint found for PayPal checkout via Swagger');
	// 		} catch (swaggerError) {
	// 			console.error('Swagger client error:', swaggerError);
	// 		}

	// 		// Fallback to direct fetch with query param for redirectBaseURL
	// 		const apiUrl = `${
	// 			window.location.origin
	// 		}/v1/subscription/${userId}/paypal/${paypalSubscriptionId}?redirectBaseURL=${encodeURIComponent(
	// 			currentDomain
	// 		)}`;
	// 		console.log('Fetching PayPal checkout details from URL:', apiUrl);

	// 		const response = await fetch(apiUrl, {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				'X-Redirect-Base-URL': currentDomain // Also include as a custom header
	// 			},
	// 			credentials: 'include' // Include cookies for auth
	// 		});

	// 		console.log('PayPal checkout response status:', response.status);

	// 		if (!response.ok) {
	// 			const errorText = await response.text();
	// 			console.error('Error response from PayPal checkout:', errorText);

	// 			try {
	// 				const errorData = JSON.parse(errorText);
	// 				throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
	// 			} catch (e) {
	// 				throw new Error(
	// 					`HTTP error! status: ${response.status}, response: ${errorText.substring(0, 100)}`
	// 				);
	// 			}
	// 		}

	// 		const data = await response.json();
	// 		console.log('PayPal checkout details retrieved:', data);
	// 		return data;
	// 	} catch (error) {
	// 		console.error('Error getting PayPal checkout details:', error);
	// 		throw error;
	// 	}
	// }

	// Update verification method to include GST information
	static async verifyPayment(userId, paymentData) {
		try {
			// Store the current URL for debugging
			const currentUrl = window.location.href;

			// Extract internal order ID from localStorage if available
			const storedOrderId = localStorage.getItem(`order_${paymentData.subscriptionId}`);
			if (storedOrderId && !paymentData.orderId) {
				console.log('Found stored internal order ID:', storedOrderId);
				paymentData.orderId = storedOrderId;
			}

			// Enhance the payment data with additional information
			const enhancedPaymentData = {
				...paymentData,
				currentUrl: currentUrl // Include current URL for debugging
			};

			// Log complete data for debugging
			console.log('Verifying payment with data:', JSON.stringify(enhancedPaymentData));

			// For INR payments, ensure we're verifying with GST
			if (
				paymentData.currency === 'INR' ||
				(paymentData.gateway === 'razorpay' && !paymentData.currency)
			) {
				// This tells the backend to include GST in the verification
				enhancedPaymentData.includeGST = true;
			}

			// First attempt - using swagger client
			try {
				const client = await swaggerClient();

				if (client?.apis?.subscription?.verifySubscriptionPayment) {
					console.log('Using swagger client for verification');
					const response = await client.apis.subscription.verifySubscriptionPayment({
						userId: userId,
						body: enhancedPaymentData
					});

					if (response.status === 200 && response.data) {
						// Clear stored order ID after successful verification
						if (storedOrderId) {
							localStorage.removeItem(`order_${paymentData.subscriptionId}`);
						}
						return JSON.parse(response.data);
					}
				}

				console.warn('No valid API endpoint found for payment verification via Swagger');
			} catch (swaggerError) {
				console.error('Swagger client error:', swaggerError);
			}

			// Fallback to direct fetch - use the correct API path
			const apiUrl = `${window.location.origin}/v1/subscription/${userId}/verify-payment`;
			console.log('Payment verification details:', {
				userId,
				gateway: enhancedPaymentData.gateway,
				subscriptionId: enhancedPaymentData.subscriptionId,
				paymentId: enhancedPaymentData.paymentId,
				orderId: enhancedPaymentData.orderId
			});
			console.log('Falling back to direct fetch at URL:', apiUrl);

			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(enhancedPaymentData),
				credentials: 'include' // Include cookies for auth
			});

			console.log('Response status:', response.status);

			if (!response.ok) {
				const errorText = await response.text();
				console.error('Error response:', errorText);

				try {
					const errorData = JSON.parse(errorText);
					throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
				} catch (e) {
					throw new Error(
						`HTTP error! status: ${response.status}, response: ${errorText.substring(0, 100)}`
					);
				}
			}

			// Clear stored order ID after successful verification
			if (storedOrderId) {
				localStorage.removeItem(`order_${paymentData.subscriptionId}`);
			}

			return await response.json();
		} catch (error) {
			console.error('Error verifying payment:', error);
			throw error;
		}
	}

	static async getRazorpayCheckoutDetails(userId, razorpaySubscriptionId) {
		try {
			// First attempt - using swagger client
			try {
				const client = await swaggerClient();

				if (client?.apis?.subscription?.getSubscriptionRazorpayCheckout) {
					const response = await client.apis.subscription.getSubscriptionRazorpayCheckout({
						userId: userId,
						razorpay_subscription_id: razorpaySubscriptionId
					});

					if (response.status === 200 && response.data) {
						return JSON.parse(response.data);
					}
				}

				console.warn('No valid API endpoint found for Razorpay checkout via Swagger');
			} catch (swaggerError) {
				console.error('Swagger client error:', swaggerError);
			}

			// Fallback to direct fetch
			const apiUrl = `${window.location.origin}/v1/subscription/${userId}/razorpay/${razorpaySubscriptionId}`;
			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				credentials: 'include' // Include cookies for auth
			});

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error('Error getting Razorpay checkout details:', error);
			throw error;
		}
	}

	static async getPaypalCheckoutDetails(userId, paypalSubscriptionId) {
		try {
			console.log('Getting PayPal checkout details', {
				userId,
				paypalSubscriptionId
			});

			// First attempt - using swagger client
			try {
				const client = await swaggerClient();

				if (client?.apis?.subscription?.getSubscriptionPaypalCheckout) {
					const response = await client.apis.subscription.getSubscriptionPaypalCheckout({
						userId: userId,
						paypal_subscription_id: paypalSubscriptionId
					});

					if (response.status === 200 && response.data) {
						return JSON.parse(response.data);
					}
				}

				console.warn('No valid API endpoint found for PayPal checkout via Swagger');
			} catch (swaggerError) {
				console.error('Swagger client error:', swaggerError);
			}

			// Fallback to direct fetch
			const apiUrl = `${window.location.origin}/v1/subscription/${userId}/paypal/${paypalSubscriptionId}`;
			console.log('Fetching PayPal checkout details from URL:', apiUrl);

			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				credentials: 'include' // Include cookies for auth
			});

			console.log('PayPal checkout response status:', response.status);

			if (!response.ok) {
				const errorText = await response.text();
				console.error('Error response from PayPal checkout:', errorText);

				try {
					const errorData = JSON.parse(errorText);
					throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
				} catch (e) {
					throw new Error(
						`HTTP error! status: ${response.status}, response: ${errorText.substring(0, 100)}`
					);
				}
			}

			const data = await response.json();
			console.log('PayPal checkout details retrieved:', data);
			return data;
		} catch (error) {
			console.error('Error getting PayPal checkout details:', error);
			throw error;
		}
	}

	// web-kamero-full/src/pouchDB/models/Subscription.js

	// Update the createSubscription method to include GST
	static async createSubscription(userId, subscriptionData) {
		try {
			// Log the subscription data
			console.log('Creating subscription with data:', JSON.stringify(subscriptionData));

			// Ensure GST fields are set for INR
			if (
				(subscriptionData.currency === 'INR' ||
					subscriptionData.checkoutType === 'INR' ||
					subscriptionData.checkoutType === 'india') &&
				!subscriptionData.gstAmount
			) {
				const baseAmount = subscriptionData.amount;
				const gstAmount = baseAmount * 0.18; // 18% GST
				const totalAmount = baseAmount + gstAmount;

				// Add GST-related fields to the subscription data
				subscriptionData.gstAmount = gstAmount;
				subscriptionData.gstPercentage = 18;
				subscriptionData.totalAmount = totalAmount;

				console.log('Added GST calculation:', {
					baseAmount,
					gstAmount,
					totalAmount
				});
			}

			// Add checkoutType if it doesn't exist
			if (!subscriptionData.checkoutType) {
				subscriptionData.checkoutType = subscriptionData.currency === 'INR' ? 'INR' : 'USD';
			}

			// Handle upgrade or addon mode
			const purchaseMode = subscriptionData.purchaseMode || 'new';

			let apiEndpoint = '';

			if (purchaseMode === 'upgrade') {
				apiEndpoint = `/v1/subscription/${userId}/upgrade`;
			} else if (purchaseMode === 'addon') {
				apiEndpoint = `/v1/subscription/${userId}/addon`;
			} else {
				apiEndpoint = `/v1/subscription/${userId}/purchase`;
			}

			// First attempt - using swagger client
			try {
				const client = await swaggerClient();

				// Check if the appropriate API endpoint exists in Swagger
				if (client?.apis?.subscription?.createSubscription && purchaseMode === 'new') {
					const response = await client.apis.subscription.createSubscription({
						userId: userId,
						body: subscriptionData
					});

					if (response.status === 200 && response.data) {
						return JSON.parse(response.data);
					}
				} else if (client?.apis?.subscription?.upgradeSubscription && purchaseMode === 'upgrade') {
					const response = await client.apis.subscription.upgradeSubscription({
						userId: userId,
						body: subscriptionData
					});

					if (response.status === 200 && response.data) {
						return JSON.parse(response.data);
					}
				} else if (client?.apis?.subscription?.addonSubscription && purchaseMode === 'addon') {
					const response = await client.apis.subscription.addonSubscription({
						userId: userId,
						body: subscriptionData
					});

					if (response.status === 200 && response.data) {
						return JSON.parse(response.data);
					}
				}

				console.warn('No valid API endpoint found for subscription creation via Swagger');
			} catch (swaggerError) {
				console.error('Swagger client error:', swaggerError);
			}

			// Fallback to direct fetch with the appropriate endpoint
			const apiUrl = `${window.location.origin}${apiEndpoint}`;
			console.log(`Using direct fetch to ${apiUrl}`);

			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(subscriptionData),
				credentials: 'include' // Include cookies for auth
			});

			if (!response.ok) {
				const errorText = await response.text();
				console.error('API error response:', errorText);

				try {
					const errorData = JSON.parse(errorText);
					throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
				} catch (e) {
					throw new Error(
						`HTTP error! status: ${response.status}, response: ${errorText.substring(0, 100)}`
					);
				}
			}

			return await response.json();
		} catch (error) {
			console.error('Error creating subscription:', error);
			throw error;
		}
	}

	// Update verification method to include GST information
	static async verifyPayment(userId, paymentData) {
		try {
			// Log complete data for debugging
			console.log('Verifying payment with data:', JSON.stringify(paymentData));

			// For INR payments, ensure we're verifying with GST
			if (
				paymentData.currency === 'INR' ||
				(paymentData.gateway === 'razorpay' && !paymentData.currency)
			) {
				// This tells the backend to include GST in the verification
				paymentData.includeGST = true;
			}

			// First attempt - using swagger client
			try {
				const client = await swaggerClient();

				if (client?.apis?.subscription?.verifySubscriptionPayment) {
					console.log('Using swagger client for verification');
					const response = await client.apis.subscription.verifySubscriptionPayment({
						userId: userId,
						body: paymentData
					});

					if (response.status === 200 && response.data) {
						return JSON.parse(response.data);
					}
				}

				console.warn('No valid API endpoint found for payment verification via Swagger');
			} catch (swaggerError) {
				console.error('Swagger client error:', swaggerError);
			}

			// Fallback to direct fetch - use the correct API path
			const apiUrl = `${window.location.origin}/v1/subscription/${userId}/verify-payment`;
			console.log('Payment verification details:', {
				userId,
				gateway: paymentData.gateway,
				subscriptionId: paymentData.subscriptionId,
				paymentId: paymentData.paymentId,
				orderId: paymentData.orderId,
				includeGST: paymentData.includeGST
			});
			console.log('Falling back to direct fetch at URL:', apiUrl);

			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(paymentData),
				credentials: 'include' // Include cookies for auth
			});

			console.log('Response status:', response.status);

			if (!response.ok) {
				const errorText = await response.text();
				console.error('Error response:', errorText);

				try {
					const errorData = JSON.parse(errorText);
					throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
				} catch (e) {
					throw new Error(
						`HTTP error! status: ${response.status}, response: ${errorText.substring(0, 100)}`
					);
				}
			}

			return await response.json();
		} catch (error) {
			console.error('Error verifying payment:', error);
			throw error;
		}
	}

	// Helper method to handle PayPal payment success
	static async handlePayPalPaymentSuccess(userId, subscriptionId, paymentId) {
		try {
			// Log the payment details for debugging
			console.log('PayPal payment successful, verifying payment:', {
				userId,
				subscriptionId,
				paymentId
			});

			// Extract the PayPal subscription ID from the URL if it's in a different format
			// PayPal might return it as part of the URL query params
			let finalSubscriptionId = subscriptionId;
			if (subscriptionId && subscriptionId.includes('?')) {
				const urlParts = subscriptionId.split('?');
				const queryParams = new URLSearchParams(urlParts[1]);

				// Look for PayPal subscription ID in params
				const paypalSubId =
					queryParams.get('subscriptionID') ||
					queryParams.get('subscription_id') ||
					queryParams.get('paypalSubscriptionId');

				if (paypalSubId) {
					console.log('Extracted PayPal subscription ID from query params:', paypalSubId);
					finalSubscriptionId = paypalSubId;
				}
			}

			// Extract orderId from the query parameters if available
			const urlParams = new URLSearchParams(window.location.search);
			const orderId = urlParams.get('orderId');
			if (orderId) {
				console.log('Found order ID in URL:', orderId);
			}

			// Ensure the PayPal subscription ID is correctly formatted
			// Most PayPal subscription IDs start with "I-"
			if (
				finalSubscriptionId &&
				!finalSubscriptionId.startsWith('I-') &&
				!finalSubscriptionId.startsWith('paypal_')
			) {
				// This might not be a PayPal ID, log a warning
				console.warn('Non-standard PayPal subscription ID format:', finalSubscriptionId);
			}

			// Construct the payment verification data
			const verificationData = {
				paymentId: paymentId,
				subscriptionId: finalSubscriptionId, // Make sure this is the exact ID from PayPal
				gateway: 'paypal',
				paypalSubscriptionId: finalSubscriptionId, // Include this as a backup
				orderId: orderId // Include orderId if available
			};

			// Call the subscription verify payment method
			const result = await this.verifyPayment(userId, verificationData);

			if (result && result.success) {
				console.log('Payment verification successful:', result);
				return result;
			} else {
				console.error('Payment verification failed:', result);
				throw new Error(result?.message || 'Payment verification failed');
			}
		} catch (error) {
			console.error('Error during PayPal payment verification:', error);
			throw error;
		}
	}
}

export default Subscription;
