// src/actions/subscriptionActions.js

import { message } from 'antd';
import Subscription from '../pouchDB/models/Subscription';
import { getCurrencyFromCountry } from '../services/CountryService';
import {
	ACTION_FETCH_SUBSCRIPTION_PLANS_FAILURE,
	ACTION_FETCH_SUBSCRIPTION_PLANS_REQUEST,
	ACTION_FETCH_SUBSCRIPTION_PLANS_SUCCESS,
	ACTION_PURCHASE_SUBSCRIPTION_FAILURE,
	ACTION_PURCHASE_SUBSCRIPTION_REQUEST,
	ACTION_PURCHASE_SUBSCRIPTION_SUCCESS,
	ACTION_UPDATE_SUBSCRIPTION
} from './action_types';

// Action Creators
export const fetchSubscriptionPlansRequest = () => ({
	type: ACTION_FETCH_SUBSCRIPTION_PLANS_REQUEST
});

export const fetchSubscriptionPlansSuccess = (plans) => ({
	type: ACTION_FETCH_SUBSCRIPTION_PLANS_SUCCESS,
	payload: plans
});

export const fetchSubscriptionPlansFailure = (error) => ({
	type: ACTION_FETCH_SUBSCRIPTION_PLANS_FAILURE,
	payload: error
});

export const purchaseSubscriptionRequest = () => ({
	type: ACTION_PURCHASE_SUBSCRIPTION_REQUEST
});

export const purchaseSubscriptionSuccess = (subscription) => ({
	type: ACTION_PURCHASE_SUBSCRIPTION_SUCCESS,
	payload: subscription
});

export const purchaseSubscriptionFailure = (error) => ({
	type: ACTION_PURCHASE_SUBSCRIPTION_FAILURE,
	payload: error
});

// Thunk Action
export const fetchSubscriptionPlans = (countryCode = 'IN') => {
	return async (dispatch) => {
		// Explicitly dispatch the request action
		dispatch(fetchSubscriptionPlansRequest());
		console.log('Dispatched REQUEST action, now fetching plans...');

		try {
			// Get currency based on country code
			const currency = getCurrencyFromCountry(countryCode);
			console.log('Fetching plans with currency:', currency);

			// Fetch plans with the determined currency
			const plans = await Subscription.fetchPlans(currency);
			console.log('Received plans from API:', plans);

			if (!plans || !Array.isArray(plans)) {
				throw new Error('Invalid response format: plans data is not an array');
			}

			// Sort plans by price for better display
			const sortedPlans = [...plans].sort((a, b) => {
				// First sort by period (yearly first, then quarterly)
				if (a.period !== b.period) {
					return a.period === 'yearly' ? -1 : 1;
				}
				// Then sort by amount (low to high)
				return a.amount - b.amount;
			});

			console.log('Dispatching SUCCESS action with sorted plans');
			dispatch(fetchSubscriptionPlansSuccess(sortedPlans));
			return sortedPlans;
		} catch (error) {
			console.error('Error in fetchSubscriptionPlans action:', error);
			dispatch(
				fetchSubscriptionPlansFailure(error.message || 'Failed to fetch subscription plans.')
			);
			return []; // Return empty array as fallback instead of throwing
		}
	};
};

export const purchaseSubscription = (userId, planData, currency) => {
	return async (dispatch) => {
		dispatch(purchaseSubscriptionRequest());

		try {
			if (!userId) {
				throw new Error('User ID is required to purchase a subscription');
			}

			// Format the subscription data with the required fields
			const subscriptionData = {
				planId: planData.id,
				currency: currency,
				isFaceRecognition: true,
				isGuestUpload: true,
				isRegistration: true,
				maxEvents: planData.maxEvents || 99999,
				guestPhotosLimit: planData.maxPhotosLimit,
				tier: planData.name.toLowerCase()
			};

			console.log('Purchasing subscription with data:', subscriptionData);

			// Call the API to create the subscription
			const subscription = await Subscription.createSubscription(userId, subscriptionData);

			if (!subscription || !subscription.checkoutLink) {
				throw new Error('Failed to create subscription: No checkout link provided');
			}

			dispatch(purchaseSubscriptionSuccess(subscription));

			// Return the subscription object so that the component can redirect to the checkout page
			return subscription;
		} catch (error) {
			const errorMessage = error.message || 'Failed to purchase subscription. Please try again.';
			console.error('Error purchasing subscription:', error);

			message.error(errorMessage);
			dispatch(purchaseSubscriptionFailure(errorMessage));

			// Re-throw to allow the component to handle the error
			throw error;
		}
	};
};

export const refreshSubscriptionDetails = () => {
	return async (dispatch, getState) => {
		try {
			const { userId, subscription } = getState().auth;
			if (!userId || !subscription?.id) {
				console.log('Cannot refresh subscription - missing userId or subscriptionId');
				return;
			}

			console.log('Refreshing subscription:', {
				userId,
				subscriptionId: subscription.id
			});

			const updatedSubscription = await Subscription.fetchSubscription(userId, subscription.id);

			console.log('Retrieved updated subscription:', {
				uploadLimit: updatedSubscription.uploadLimit,
				uploadedPhotos: updatedSubscription.uploadedPhotosCount,
				maxPhotosLimit: updatedSubscription.maxPhotosLimit,
				currentPhotos: updatedSubscription.currentPhotosCount
			});

			dispatch({
				type: ACTION_UPDATE_SUBSCRIPTION,
				payload: updatedSubscription
			});

			return updatedSubscription;
		} catch (error) {
			console.error('Failed to refresh subscription details:', error);
			return null; // Return null instead of throwing
		}
	};
};
