import {
	ACTION_FETCH_SUBSCRIPTION_PLANS_FAILURE,
	ACTION_FETCH_SUBSCRIPTION_PLANS_REQUEST,
	ACTION_FETCH_SUBSCRIPTION_PLANS_SUCCESS
} from '../actions/action_types';

// Add console logs for debugging
console.log('Initializing subscription reducer');

const initialState = {
	plans: [],
	error: null,
	loading: false
};

const subscriptionReducer = (state = initialState, action) => {
	console.log('Subscription reducer received action:', action.type);

	switch (action.type) {
		case ACTION_FETCH_SUBSCRIPTION_PLANS_REQUEST:
			console.log('Handling REQUEST action in reducer');
			return {
				...state,
				loading: true,
				error: null
			};

		case ACTION_FETCH_SUBSCRIPTION_PLANS_SUCCESS:
			console.log('Handling SUCCESS action in reducer with plans:', action.payload?.length || 0);
			return {
				...state,
				plans: action.payload,
				loading: false,
				error: null
			};

		case ACTION_FETCH_SUBSCRIPTION_PLANS_FAILURE:
			console.log('Handling FAILURE action in reducer with error:', action.payload);
			return {
				...state,
				plans: [],
				loading: false,
				error: action.payload
			};

		default:
			return state;
	}
};

export default subscriptionReducer;
