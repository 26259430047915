import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import auth from './authReducer';
import post from './postReducer';
import pouchDB from './pouchDBReducer';
import profile from './profileReducer';
import replication from './replicationReducer';
import subscription from './subscriptionReducer';
import upload from './uploadReducer';
import videoLink from './videoLinkReducer';

const rootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		auth,
		replication,
		pouchDB,
		upload,
		videoLink,
		post,
		profile,
		subscription
	});

export default rootReducer;
