// src/services/CountryService.js

/**
 * Get user's public IP address
 * @returns {Promise<string>} User's public IP address
 */
const getPublicIP = async () => {
	try {
		// Use a public service to get the client's real IP
		const response = await fetch('https://api.ipify.org?format=json');
		const data = await response.json();
		return data.ip;
	} catch (error) {
		console.error('Error fetching public IP:', error);
		throw new Error('Could not determine public IP address');
	}
};

/**
 * Get user's country code from GeoIP API using server-side endpoint
 * @returns {Promise<string>} Country code (e.g., 'US', 'IN', etc.)
 */
export const getUserCountry = async () => {
	try {
		// First get the user's real public IP address
		const ipAddress = await getPublicIP();

		// Then use the server-side endpoint with the specific IP
		const response = await fetch(`https://geoip.kamero.click/api/v1/server/country/${ipAddress}`, {
			method: 'GET',
			headers: {
				'X-API-Key': 'geoip_server_gVjNQvezRP2mtj6H7Z8Amn1QMnbNyssm7I6tZF0R55g',
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: window.location.origin
			},
			mode: 'cors'
		});

		if (!response.ok) {
			throw new Error(`GeoIP API error: ${response.status}`);
		}

		const data = await response.json();
		return data.country_code;
		// return 'US';
	} catch (error) {
		console.error('Error determining user country:', error);
		// Fallback to India if there's any error
		return 'IN';
	}
};

/**
 * Alternative implementation that uses public IP lookup service first
 * and then passes that to the GeoIP service
 * @returns {Promise<string>} Country code (e.g., 'US', 'IN', etc.)
 */
export const getUserCountryWithFallback = async () => {
	try {
		// Try the server-side endpoint first
		const ipAddress = await getPublicIP();
		const response = await fetch(`https://geoip.kamero.click/api/v1/server/country/${ipAddress}`, {
			method: 'GET',
			headers: {
				'X-API-Key': 'geoip_server_gVjNQvezRP2mtj6H7Z8Amn1QMnbNyssm7I6tZF0R55g',
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			mode: 'cors'
		});

		if (!response.ok) {
			throw new Error(`GeoIP API error: ${response.status}`);
		}

		const data = await response.json();
		return data.country_code;
	} catch (serverError) {
		console.error('Server-side GeoIP lookup failed:', serverError);

		try {
			// Fallback to client-side endpoint as a backup
			const fallbackResponse = await fetch('https://geoip.kamero.click/api/v1/client/country', {
				method: 'GET',
				headers: {
					'X-API-Key': 'geoip_client_cQaiGsm9WmTuS9nA1pCqt0fAe7riiQQ4wCChy3zbO1E',
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Origin: window.location.origin
				},
				mode: 'cors'
			});

			if (!fallbackResponse.ok) {
				throw new Error(`Fallback GeoIP API error: ${fallbackResponse.status}`);
			}

			const fallbackData = await fallbackResponse.json();
			return fallbackData.country_code;
		} catch (fallbackError) {
			console.error('Both GeoIP lookup methods failed:', fallbackError);
			// Ultimate fallback to India
			return 'IN';
		}
	}
};

/**
 * Get the appropriate currency based on country code
 * @param {string} countryCode - The user's country code
 * @returns {string} Currency code ('INR' for India, 'USD' for others)
 */
export const getCurrencyFromCountry = (countryCode) => {
	return countryCode === 'IN' ? 'INR' : 'USD';
};
