import {
	ACTION_ALBUM_CLICKED,
	ACTION_ALBUM_CREATED,
	ACTION_ALBUM_DELETE_FINISHED,
	ACTION_ALBUM_DELETE_STARTED,
	ACTION_ALBUM_DELETED,
	ACTION_ALBUM_GUEST_UPDATED,
	ACTION_ALBUM_HOME_UPDATED,
	ACTION_ALBUM_UPDATED,
	ACTION_API_ERROR_OCCURED,
	ACTION_EVENT_CLICKED,
	ACTION_EVENT_HOME_UPDATED,
	ACTION_EVENT_UPDATED,
	ACTION_EXPIRED_EVENTS_UPDATED,
	ACTION_EXPIRED_FACES_EVENTS_UPDATED,
	ACTION_GUEST_UPLOAD_REFRESHING,
	ACTION_MY_EVENTS_UPDATED,
	ACTION_PHOTO_COUNT_UPDATED,
	ACTION_PHOTOS_LOAD_ERROR,
	ACTION_PHOTOS_LOAD_SUCCESS,
	ACTION_PHOTOS_LOADING,
	ACTION_SEARCH_EVENTS_UPDATED,
	ACTION_TOTAL_EVENTS_UPDATED,
	ACTION_UPDATE_CLICKED_ALBUM,
	ACTION_VISIBLE_ALBUMS_UPDATED,
	ACTION_VISIBLE_EVENT_UPDATED,
	ACTION_VISIBLE_PHOTOS_PAGE_UPDATED,
	ACTION_VISIBLE_PHOTOS_UPDATED
} from './action_types';

import arrayMove from 'array-move';
import { push, replace } from 'connected-react-router';
import Album from '../pouchDB/models/Album';
import Event from '../pouchDB/models/Event';
import Photo from '../pouchDB/models/Photo';
import { storeViewState } from './navigationActions';
import { refreshSubscriptionDetails } from './subscriptionActions';
import { fetchVideoLinksMAction } from './videoLinkActions';

export const setPhotosLoading = (isLoading) => ({
	type: ACTION_PHOTOS_LOADING,
	isLoading
});

export const setPhotosLoadError = (error) => ({
	type: ACTION_PHOTOS_LOAD_ERROR,
	error
});

export const setPhotosLoadSuccess = (photos) => ({
	type: ACTION_PHOTOS_LOAD_SUCCESS,
	photos
});

const totalEventsUpdated = (count) => ({
	type: ACTION_TOTAL_EVENTS_UPDATED,
	totalEvents: count
});

export const clearSearchedEvents = () => ({
	type: ACTION_SEARCH_EVENTS_UPDATED,
	events: undefined
});

const searchedEventsUpdated = (events) => ({
	type: ACTION_SEARCH_EVENTS_UPDATED,
	events: events
});

const myEventsUpdated = (events, eventPage) => ({
	type: ACTION_MY_EVENTS_UPDATED,
	events: events,
	eventPage: eventPage
});

export const apiErrorOccured = (error) => ({
	type: ACTION_API_ERROR_OCCURED,
	error: error
});

export const eventClickedAction = (event, db) => ({
	type: ACTION_EVENT_CLICKED,
	event: event
});

export const eventUpdated = (eventIndex, event) => ({
	type: ACTION_EVENT_UPDATED,
	event: event,
	eventIndex: eventIndex
});

export const visibleEventUpdated = (event) => ({
	type: ACTION_VISIBLE_EVENT_UPDATED,
	event: event
});

export const albumClickedAction = (album) => ({
	type: ACTION_ALBUM_CLICKED,
	album: album
});

export const updateAlbumClickedAction = (album) => ({
	type: ACTION_UPDATE_CLICKED_ALBUM,
	album: album
});

export const eventHomeUpdated = (eventHome) => ({
	type: ACTION_EVENT_HOME_UPDATED,
	eventHome: eventHome
});

export const albumHomeUpdated = (albumHome) => ({
	type: ACTION_ALBUM_HOME_UPDATED,
	albumHome: albumHome
});

export const albumGuestUpdated = (guestAlbum) => ({
	type: ACTION_ALBUM_GUEST_UPDATED,
	guestAlbum
});

export const albumsUpdated = (albums) => ({
	type: ACTION_VISIBLE_ALBUMS_UPDATED,
	albums: albums
});

export const albumCreated = (album) => ({
	type: ACTION_ALBUM_CREATED,
	album: album
});

export const albumUpdated = (albumIndex, album) => ({
	type: ACTION_ALBUM_UPDATED,
	albumIndex: albumIndex,
	album: album
});

export const albumDeleted = (albumIndex) => ({
	type: ACTION_ALBUM_DELETED,
	albumIndex: albumIndex
});

export const photosUpdated = (photos) => ({
	type: ACTION_VISIBLE_PHOTOS_UPDATED,
	photos: photos
});

export const photosPageUpdated = (page, photos) => ({
	type: ACTION_VISIBLE_PHOTOS_PAGE_UPDATED,
	currentPage: page,
	photos: photos
});

export const updatePhotoCountData = (countData) => ({
	type: ACTION_PHOTO_COUNT_UPDATED,
	photoCounts: countData
});

export const albumDeleteStarted = (albumId) => ({
	type: ACTION_ALBUM_DELETE_STARTED,
	albumId: albumId
});

export const albumDeleteFinished = (albumId) => ({
	type: ACTION_ALBUM_DELETE_FINISHED,
	albumId: albumId
});

export const clearEventHome = (dispatch, getState) => {
	dispatch(eventClickedAction(undefined));
	dispatch(albumsUpdated([]));
	dispatch(albumClickedAction(undefined));
	dispatch(photosUpdated([]));
};

export const goPouchDBPostLogin = () => {
	return (dispatch, getState) => {
		dispatch(updateMyEventsMAction(1, false));
		updateTotalEventsCount(dispatch, getState().auth.userId);

		// Add this line to refresh subscription details after login
		console.log('Refreshing subscription details after login');
		try {
			dispatch(refreshSubscriptionDetails());
		} catch (error) {
			console.error('Error refreshing subscription details:', error);
			dispatch(apiErrorOccured('Error loading subscription details'));
		}
	};
};

const updateTotalEventsCount = (dispatch, userId) => {
	Event.getTotalEventCount(userId)
		.then((eventCount) => {
			dispatch(totalEventsUpdated(eventCount));
		})
		.catch((err) => {
			console.log(err);
			console.log('API_ERROR');
		});
};

export const updateMyEventsMAction = (page, consistent) => {
	return (dispatch, getState) => {
		let userId = getState().auth.userId;

		return Event.fetchEvents(userId, page - 1, consistent)
			.then((events) => {
				if (events.length === 0 && page > 1) {
					dispatch(updateMyEventsMAction(page - 1, consistent));
					//update total count as well.
					updateTotalEventsCount(dispatch, userId);
					return;
				}

				console.log('event updates', events);

				let pendingPhotoCountEventDocIds = [];
				events.forEach((event) => {
					if (!getState().pouchDB.photoCounts[event.id]) {
						pendingPhotoCountEventDocIds.push(event.id);
					}
				});

				if (pendingPhotoCountEventDocIds.length > 0) {
					Event.fetchEventCounts(pendingPhotoCountEventDocIds).then((photoCounts) => {
						dispatch(updatePhotoCount(photoCounts));
					});
				}
				dispatch(myEventsUpdated(events, page));
			})
			.catch((err) => {
				console.log(err);
				dispatch(apiErrorOccured('Error fetching Events. Please reload page.'));
			});
	};
};

export const refreshVisibleEvent = (updatedEvent) => {
	return (dispatch, getState) => {
		dispatch(visibleEventUpdated(updatedEvent));
		dispatch(updateMyEventsMAction(getState().pouchDB.eventPage, true));
	};
};

const fetchGuestUploads = (event, album, dispatch, showLoader = true) => {
	showLoader &&
		dispatch({
			type: ACTION_GUEST_UPLOAD_REFRESHING,
			value: true
		});
	Album.fetchGuestUploadAlbum(event)
		.then((guestAlbum) => {
			let photoCounts = {};
			photoCounts[album.id] = { count: guestAlbum?.photoCount || 0 };
			dispatch(updatePhotoCount(photoCounts));
			dispatch(albumGuestUpdated(guestAlbum));
		})
		.catch((err) => {
			console.log(err);
			console.log('Guest Upload API ERROR');
		})
		.finally(() => {
			showLoader &&
				dispatch({
					type: ACTION_GUEST_UPLOAD_REFRESHING,
					value: false
				});
		});
};

export const refreshPhotos = (event, album, consistent) => {
	return async (dispatch) => {
		try {
			// Set initial loading state
			dispatch(setPhotosLoading(true));
			dispatch(photosUpdated([]));

			if (album.isGuestUploads) {
				try {
					await fetchGuestUploads(event, album, dispatch, false);
				} catch (error) {
					console.error('Error fetching guest uploads:', error);
					dispatch(setPhotosLoadError(error));
				}
			} else {
				try {
					const photos = await Photo.fetchPhotos(event, album.id, 0, consistent);
					if (Array.isArray(photos)) {
						dispatch(photosUpdated(photos));
						dispatch(setPhotosLoadSuccess(photos));
					} else {
						throw new Error('Invalid photos data received');
					}
				} catch (error) {
					console.error('API_ERROR:', error);
					dispatch(setPhotosLoadError(error));
					dispatch(photosUpdated([]));
				}
			}
		} catch (error) {
			console.error('Unexpected error:', error);
			dispatch(setPhotosLoadError(error));
		} finally {
			dispatch(setPhotosLoading(false));
		}
	};
};

// Update photosPageSelected action
export const photosPageSelected = (event, album, pageNumber) => {
	return async (dispatch) => {
		try {
			dispatch(setPhotosLoading(true));

			if (album.isGuestUploads) {
				try {
					const guestAlbum = await Album.fetchGuestUploadAlbum(event, true, pageNumber - 1);
					const photoCounts = {
						[album.id]: { count: guestAlbum?.photoCount || 0 }
					};
					dispatch(updatePhotoCount(photoCounts));
					dispatch(albumGuestUpdated(guestAlbum));
					dispatch(photosPageUpdated(pageNumber, guestAlbum?.photos));
					dispatch(setPhotosLoadSuccess(guestAlbum?.photos));
				} catch (error) {
					console.error('Guest Upload API ERROR:', error);
					dispatch(setPhotosLoadError(error));
				}
			} else {
				try {
					const photos = await Photo.fetchPhotos(event, album.id, pageNumber - 1, true);
					dispatch(photosPageUpdated(pageNumber, photos));
					dispatch(setPhotosLoadSuccess(photos));
				} catch (error) {
					console.error('API_ERROR:', error);
					dispatch(setPhotosLoadError(error));
				}
			}
		} finally {
			dispatch(setPhotosLoading(false));
		}
	};
};

// web-kamero-full/src/actions/pouchDBActions.js
// Add this import at the top of the file

// Add this code in the appropriate sections of the file

// Update the albumClicked function to store state
export const albumClicked = (event, album) => {
	return (dispatch, getState) => {
		// First dispatch the regular albumClickedAction
		if (album.isGuestUploads) {
			dispatch(albumClickedAction(album));
			const isCached = album.id in getState().pouchDB.photoCounts;
			if (!isCached) fetchGuestUploads(event, album, dispatch);
			dispatch(refreshPhotos(event, album, false));
		} else {
			dispatch(albumClickedAction(album));
			if (getState().pouchDB.photoCounts[album.id]) {
				console.log(getState, 'check');
				//Already photo count is present for this album so just get photo batch
				dispatch(refreshPhotos(event, album, false));
			} else {
				Album.fetchAlbumHome(event, album.id)
					.then((albumHome) => {
						let photoCounts = {};
						photoCounts[album.id] = { count: albumHome.albumPhotoCount };
						dispatch(updatePhotoCount(photoCounts));
						dispatch(albumHomeUpdated(albumHome));
						dispatch(refreshPhotos(event, album, false));
					})
					.catch((err) => {
						console.log(err);
						console.log('API_ERROR');
					});
			}
		}

		// Store the current state for possible later restoration
		if (event && album) {
			const currentPage = getState().pouchDB.currentPage || 1;
			dispatch(storeViewState(event.id, album.id, currentPage));
		}
	};
};

export const refreshAlbums = (event) => {
	return (dispatch) => {
		Album.fetchAlbums(event.id)
			.then((albums) => {
				dispatch(albumsUpdated(albums));
				if (albums.length > 0) {
					dispatch(albumClicked(event, albums[0]));
				}
			})
			.catch((err) => {
				console.log('API_ERROR');
				console.log(err);
			});
	};
};

export const eventClicked = (event) => {
	return (dispatch, getState) => {
		fetchGuestUploads(
			event,
			{
				isGuestUploads: true,
				name: 'Guest Uploads',
				id: 'guestUploads'
			},
			dispatch
		);
		dispatch(eventClickedAction(event));
		dispatch(push('/events/' + event.id));
		dispatch(refreshEventHome(event));
		dispatch(fetchVideoLinksMAction(event.id));
		// dispatch(fetchGuestImageAction(event.id))
	};
};

export const updatePhotoCount = (photoCounts) => {
	return (dispatch, getState) => {
		dispatch(updatePhotoCountData(Object.assign(getState().pouchDB.photoCounts, photoCounts)));
	};
};

export const refreshEventHome = (event, album, consistent) => {
	return (dispatch, getState) => {
		Event.fetchEventHome(event, album, consistent)
			.then((eventHome) => {
				let photoCounts = {};
				photoCounts[event.id] = { count: eventHome.eventPhotoCount };
				if (eventHome.albumHome) {
					photoCounts[eventHome.albumHome.album.id] = {
						count: eventHome.albumHome.albumPhotoCount
					};
				}
				dispatch(updatePhotoCountData(Object.assign(getState().pouchDB.photoCounts, photoCounts)));
				console.log(eventHome);
				dispatch(eventHomeUpdated(eventHome));
			})
			.catch((err) => {
				console.log(err);
				console.log('API_ERROPR');
			});
	};
};

export const updateFullScreenPhotoIndex = (index, currentPath, shouldReplace) => {
	return (dispatch) => {
		if (shouldReplace) {
			currentPath.split('/');
			var paths = currentPath.split('/');
			paths.splice(paths.length - 1, 1);
			paths.push(index);
			dispatch(replace(paths.join('/')));
			return;
		}
		dispatch(push(currentPath + '/' + index));
	};
};

export const setCoverPhoto = (albumIndex, event, album, photo, pageNumber) => {
	return (dispatch, getState) => {
		Album.setCoverPhoto(getState().auth.userId, photo.id)
			.then((updatedAlbum) => {
				dispatch(albumUpdated(albumIndex, updatedAlbum));
				dispatch(updateAlbumClickedAction(updatedAlbum));
			})
			.catch((err) => {
				console.log('API_ERROR');
				console.log(err);
			});
	};
};

export const setEventCoverPhotoMAction = (event, photo) => {
	return (dispatch, getState) => {
		Event.setCoverPhoto(getState().auth.userId, photo.id)
			.then((coverPhotoS3Key) => {
				dispatch(updateMyEventsMAction(getState().pouchDB.eventPage, true));
				let updatedEvent = Object.assign({}, event);
				updatedEvent.coverPhotoS3Key = coverPhotoS3Key;
				dispatch(visibleEventUpdated(updatedEvent));
			})
			.catch((err) => {
				console.log('API_ERROR');
				console.log(err);
			});
	};
};

export const createAlbumMAction = (userId, event, name) => {
	return (dispatch, getState) => {
		Album.createAlbum(userId, event.id, name)
			.then((newAlbum) => {
				dispatch(albumCreated(newAlbum));
				let albums = getState().pouchDB.visibleAlbums;
				if (albums.length === 1) {
					dispatch(albumClicked(event, albums[0]));
				}
			})
			.catch((err) => {
				console.log('API_ERROR');
				console.log(err);
			});
	};
};

export const albumSortOrderChanged = (oldIndex, newIndex) => {
	return async (dispatch, getState) => {
		let albums = getState().pouchDB.visibleAlbums;
		let newAlbumsOrder = arrayMove(albums, oldIndex, newIndex);
		try {
			dispatch(albumsUpdated(newAlbumsOrder));
			await Event.updateAlbumsOrder(
				getState().auth.userId,
				getState().pouchDB.visibleEvent.id,
				newAlbumsOrder.map((album) => album.id)
			);
		} catch (e) {
			dispatch(albumsUpdated(albums));
			throw e;
		}
	};
};

export const editAlbumName = (albumIndex, event, albumDocId, newAlbumName) => {
	return (dispatch, getState) => {
		Album.updateAlbumName(getState().auth.userId, albumDocId, newAlbumName)
			.then((album) => {
				dispatch(albumUpdated(albumIndex, album));
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const deleteAlbum = (albumIndex, event, albumDocId) => {
	return (dispatch, getState) => {
		dispatch(albumDeleteStarted(albumDocId));
		Album.deleteAlbum(getState().auth.userId, albumDocId, event.id)
			.then(() => {
				dispatch(albumDeleteFinished(albumDocId));
				dispatch(albumDeleted(albumIndex));
				let albums = getState().pouchDB.visibleAlbums;
				Event.fetchEventCounts([event.id]).then((photoCounts) => {
					dispatch(updatePhotoCount(photoCounts));
				});
				if (albums.length > 0) {
					dispatch(albumClicked(event, albums[0]));
				} else {
					dispatch(albumClickedAction(undefined));
				}
			})
			.catch((err) => {
				dispatch(albumDeleteFinished(albumDocId));
				console.log(err);
			});
	};
};

export const deletePhoto = (userId, event, album, photo, pageNumber) => {
	return (dispatch) => {
		Photo.deletePhoto(userId, photo)
			.then(() => {
				//fetch album count including event
				Album.fetchAlbumCounts(event.id, true, [album.id]).then((photoCounts) => {
					dispatch(updatePhotoCount(photoCounts));
				});
				dispatch(photosPageSelected(event, album, pageNumber));
			})
			.catch((err) => console.log(err));
	};
};

export const deletePhotosMAction = (event, album, photoDocIds) => {
	return async (dispatch, getState) => {
		await Photo.deletePhotos(getState().auth.userId, photoDocIds);
		dispatch(albumClicked(event, album));
		let photoCounts = await Album.fetchAlbumCounts(event.id, true, [album.id]);
		dispatch(updatePhotoCount(photoCounts));
	};
};

export const movePhotosMAction = (event, album, toAlbumDocId, photoDocIds) => {
	return async (dispatch, getState) => {
		await Photo.movePhotos(getState().auth.userId, toAlbumDocId, photoDocIds);
		dispatch(albumClicked(event, album));
		let photoCounts = await Album.fetchAlbumCounts(event.id, true, [album.id, toAlbumDocId]);
		dispatch(updatePhotoCount(photoCounts));
	};
};

export const photosUploadedMAction = (event, album) => {
	return (dispatch) => {
		dispatch(refreshEventHome(event, album, true));
	};
};

export const editEventMAction = (index, userId, event, newEventName) => {
	return (dispatch) => {
		Event.updateEventName(userId, event.id, newEventName)
			.then(() => {
				event.name = newEventName;
				dispatch(eventUpdated(index, event));
			})
			.catch((err) => {
				console.log('API_ERROR');
				console.log(err);
			});
	};
};

export const editEventExpiryMAction = (index, userId, event, newExpiry) => {
	return async (dispatch) => {
		await Event.updateEventExpiry(userId, event.id, newExpiry);
		event.expiresAt = newExpiry;
		dispatch(eventUpdated(index, event));
	};
};

export const searchEventMAction = (userId, searchText) => {
	return (dispatch) => {
		return Event.searchEvents(userId, searchText)
			.then((events) => {
				dispatch(searchedEventsUpdated(events));
			})
			.catch((err) => {
				console.log('API_ERROR');
				console.log(err);
			});
	};
};

export const setEventIdMAction = (index, userId, event, newEventId) => {
	return (dispatch) => {
		Event.assignHashId(userId, event.id, newEventId)
			.then(() => {
				event.eventId = newEventId;
				dispatch(eventUpdated(index, event));
			})
			.catch((error) => {
				dispatch(apiErrorOccured(JSON.parse(error.response.text).message));
			});
	};
};

export const deleteEventMAction = (eventId, userId) => {
	return (dispatch, getState) => {
		Event.deleteEvent(eventId, userId)
			.then(() => {
				dispatch(updateMyEventsMAction(getState().pouchDB.eventPage, true));
			})
			.catch((err) => {
				console.log('API_ERROR');
				console.log(err);
			});
	};
};

export const perPagePhotoCountChangedMAction = (newPhotoCount) => {
	return (dispatch, getState) => {
		Photo.updatePhotosPerPage(newPhotoCount);

		let event = getState().pouchDB.visibleEvent;
		let album = getState().pouchDB.clickedAlbum;

		dispatch(refreshPhotos(event, album, false));
	};
};

export const fetchExpiredEventsMAction = () => {
	return (dispatch, getState) => {
		const userId = getState().auth.userId;
		return Event.fetchExpiredEvents(userId)
			.then((expiredEvents) => {
				dispatch({
					type: ACTION_EXPIRED_EVENTS_UPDATED,
					expiredEvents
				});
			})
			.catch((err) => {
				console.log('API_ERROR');
				console.log(err);
			});
	};
};

export const fetchExpiredFacesEventsMAction = () => {
	return (dispatch, getState) => {
		const userId = getState().auth.userId;
		return Event.fetchFaceExpiringEvents(userId)
			.then((expiredFacesEvents) => {
				dispatch({
					type: ACTION_EXPIRED_FACES_EVENTS_UPDATED,
					expiredFacesEvents
				});
			})
			.catch((err) => {
				console.log('API_ERROR');
				console.log(err);
			});
	};
};
